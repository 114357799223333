var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"12"}},[_c('v-expansion-panels',[_c('v-expansion-panel',[_c('v-expansion-panel-header',[_vm._v(" Instructions, Methodology, and Data Sources ")]),_c('v-expansion-panel-content',[_c('v-card',{attrs:{"flat":""}},[_c('v-card-text',[_c('HydraulicConnectivityInstructions')],1)],1)],1)],1)],1)],1)],1),_c('v-row',[(_vm.record && _vm.record.geometry)?_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('div',{staticClass:"pa-3 mt-3"},[_vm._v(" Point at "+_vm._s(_vm.record.geometry.coordinates.map(function (x) { return x.toFixed(6); }).join(', '))+" ")])]):_vm._e(),_c('v-col',{staticClass:"text-right"},[_c('SavedAnalysesCreateModal',{attrs:{"geometry":_vm.record.geometry,"featureType":"assign-demand"}}),_c('v-btn',{attrs:{"color":"primary","outlined":""},on:{"click":_vm.selectPointOfInterest}},[_vm._v("Select a New Point")]),(_vm.streams && _vm.streams.length)?_c('v-btn',{staticClass:"mx-1 my-2",attrs:{"outlined":"","disabled":_vm.loading,"color":"primary"},on:{"click":_vm.submitStreamsForExport}},[_vm._v(" Excel "),(!_vm.spreadsheetLoading)?_c('v-icon',{staticClass:"ml-1"},[_vm._v("cloud_download")]):_vm._e(),(_vm.spreadsheetLoading)?_c('v-progress-circular',{staticClass:"ml-1",attrs:{"indeterminate":"","size":"24","color":"primary"}}):_vm._e()],1):_vm._e()],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"6","md":"1"}},[_c('span',{staticClass:"text-sm-right"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"color":"blue-grey","icon":""},on:{"click":_vm.toggleMultiSelect}},on),[(_vm.multiSelect)?_c('v-icon',[_vm._v("mdi-cancel")]):_c('v-icon',[_vm._v("mdi-pencil-box-multiple-outline")])],1)]}}])},[(!_vm.multiSelect)?_c('span',[_vm._v("Remove multiple streams")]):_c('span',[_vm._v("Cancel")])]),_c('span',{directives:[{name:"show",rawName:"v-show",value:(_vm.multiSelect),expression:"multiSelect"}]},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"color":"red darken-4","icon":""},on:{"click":_vm.removeSelected}},on),[_c('v-icon',[_vm._v("mdi-trash-can-outline")])],1)]}}])},[_c('span',[_vm._v("Remove selected")])])],1)],1)]),_c('v-col',{attrs:{"cols":"6","md":"3"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({directives:[{name:"show",rawName:"v-show",value:(_vm.show.removeOverlaps),expression:"show.removeOverlaps"}],staticClass:"mb-1 mt-1",attrs:{"x-small":"","color":"blue-grey lighten-4"},on:{"click":_vm.removeOverlaps}},on),[_c('span',{staticClass:"hidden-sm-and-down"},[_vm._v("Remove overlaps")])])]}}])},[_c('span',[_vm._v("Remove overlapping streams")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({directives:[{name:"show",rawName:"v-show",value:(_vm.show.removeLowApportionment),expression:"show.removeLowApportionment"}],staticClass:"mb-1 mt-1",attrs:{"x-small":"","color":"blue-grey lighten-4"},on:{"click":function($event){return _vm.removeStreamsWithLowApportionment(_vm.apportionmentMin)}}},on),[_c('span',{staticClass:"hidden-sm-and-down"},[_vm._v("Remove less than 10%")])])]}}])},[_c('span',[_vm._v("Remove streams that have less than 10% apportionment")])])],1),_c('v-col',{attrs:{"cols":"12","md":"3"}},[_c('v-text-field',{attrs:{"dense":"","label":"Weighting Factor","rules":[_vm.weightingFactorValidation.number, _vm.weightingFactorValidation.values, _vm.weightingFactorValidation.required]},model:{value:(_vm.weightingFactor),callback:function ($$v) {_vm.weightingFactor=$$v},expression:"weightingFactor"}})],1),_c('v-col',[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [(_vm.app && _vm.app.config.hydraulic_connectivity_custom_stream_points)?_c('v-btn',_vm._g({staticClass:"blue-grey lighten-4",attrs:{"small":""},on:{"click":_vm.addNewStreamPoint}},on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-plus-circle-outline")]),_vm._v(" New stream point ")],1):_vm._e()]}}])},[_c('span',[_vm._v("Add a new stream point")])])],1),_c('v-col',[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({directives:[{name:"show",rawName:"v-show",value:(_vm.show.reloadAll),expression:"show.reloadAll"}],staticClass:"ma-2",attrs:{"small":"","color":"blue-grey","dark":"","tile":"","icon":""},on:{"click":_vm.fetchStreams}},on),[_c('v-icon',[_vm._v("mdi-restore")])],1)]}}])},[_c('span',[_vm._v("Reset all streams. WARNING: This will remove any custom-added stream points.")])])],1),(!_vm.isFreshwaterAtlasStreamNetworksLayerEnabled)?_c('v-col',{attrs:{"align-self":"center"}},[_c('div',{staticClass:"caption"},[_c('a',{attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.enableFreshwaterAtlasStreamNetworksLayer($event)}}},[_vm._v("Enable streams map layer")])])]):_vm._e()],1),_c('v-row',[_c('v-col',[_c('v-data-table',{attrs:{"show-select":_vm.multiSelect,"hide-default-footer":"","loading":_vm.loading,"headers":_vm.headers,"item-key":"id","disable-pagination":"","items":_vm.streams},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('tr',{on:{"mouseenter":function($event){return _vm.highlight(item)},"mouseleave":function($event){return _vm.highlightAll()}}},[_c('td',[_vm._v(_vm._s(item.gnis_name))]),_c('td',{staticClass:"text-right"},[_vm._v(_vm._s(_vm._f("formatNumber")(item.length_metre.toFixed(2))))]),_c('td',{staticClass:"text-right"},[_vm._v(_vm._s(_vm._f("formatNumber")(item.distance.toFixed(2))))]),_c('td',{staticClass:"text-right"},[_vm._v(_vm._s(item.apportionment.toFixed(2))+"%")]),_c('td',[_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":""},on:{"click":function($event){return _vm.deleteStream(item)}}},on),[_c('v-icon',{attrs:{"small":""}},[_vm._v(" delete ")])],1)]}}],null,true)},[_c('span',[_vm._v("Remove")])])],1)])]}}]),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }