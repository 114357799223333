var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"ml-3 selection-menu-buttons",attrs:{"color":"grey darken-3","tile":"","text":""}},on),[_vm._v(" Tools "),_c('v-icon',[_vm._v("keyboard_arrow_down")])],1)]}}])},[_c('v-list',[_vm._l((_vm.toolOptions),function(item,index){return _c('v-list-item',{key:index,attrs:{"active-class":"font-weight-bold","to":item.route},on:{"click":_vm.openInfoPanelIfClosed}},[_c('v-list-item-icon',[(item.icon)?_c('v-icon',[_vm._v(_vm._s(item.icon))]):_vm._e()],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(item.title))])],1)],1)}),_c('v-list-item',{on:{"click":_vm.resetSelections}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-delete-outline")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Reset selections")])],1)],1)],2)],1),_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"ml-3 selection-menu-buttons",attrs:{"color":"grey darken-3","tile":"","text":""}},on),[_vm._v(" Analysis "),_c('v-icon',[_vm._v("keyboard_arrow_down")])],1)]}}])},[_c('v-list',_vm._l((_vm.analysisOptions),function(item,index){return _c('v-list-item',{key:index,attrs:{"to":item.route,"active-class":"font-weight-bold"}},[_c('v-list-item-title',[_vm._v(_vm._s(item.title))])],1)}),1)],1),(this.app.config && this.app.config.projects)?_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',{staticClass:"ml-3 selection-menu-buttons",attrs:{"color":"grey darken-3","tile":"","text":"","to":'/projects'}},[_vm._v(" Projects ")])]}}],null,false,3927313680)}):_vm._e(),(this.app.config && this.app.config.saved_analysis)?_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',{staticClass:"ml-3 selection-menu-buttons",attrs:{"color":"grey darken-3","tile":"","text":"","to":'/saved-analyses'}},[_vm._v(" My Saved Analyses ")])]}}],null,false,3032294985)}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }