var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"d-flex flex-column folders-tree-card",attrs:{"flat":"","tile":"","width":"250","min-height":"380"}},[_c('div',{staticClass:"grow scroll-x"},[_c('v-treeview',{staticClass:"folders-tree",attrs:{"open":_vm.open,"active":_vm.active,"items":_vm.projectTree,"search":_vm.filter,"item-key":"id","item-text":"name","dense":"","activatable":"","transition":"","open-on-click":""},on:{"update:active":_vm.selectProject,"update:open":_vm.toggleProjectsFolder},scopedSlots:_vm._u([{key:"prepend",fn:function(ref){
var item = ref.item;
var open = ref.open;
return [_c('span',{staticClass:"mr-2"},[_vm._v(_vm._s(item.fileCount))]),(item.type === 'root' || item.type === 'dir')?_c('v-icon',[_vm._v(" "+_vm._s(open ? 'mdi-folder-open-outline' : 'mdi-folder-outline')+" ")]):_vm._e(),(item.type === 'root' || item.type === 'dir')?_c('span',{staticClass:"ml-1 overflow-hidden"},[_vm._v(" "+_vm._s(item.name)+" ")]):_c('span',[_vm._v(_vm._s(item.filename))])]}},{key:"label",fn:function(ref){
var item = ref.item;
return [_c('span')]}}])})],1),_c('v-divider'),_c('v-toolbar',{staticClass:"shrink",attrs:{"dense":"","flat":""}},[_c('v-text-field',{staticClass:"ml-n3",attrs:{"solo":"","flat":"","hide-details":"","label":"Filter","prepend-inner-icon":"mdi-filter-outline"},model:{value:(_vm.filter),callback:function ($$v) {_vm.filter=$$v},expression:"filter"}}),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":""},on:{"click":_vm.init}},on),[_c('v-icon',[_vm._v("mdi-collapse-all-outline")])],1)]}}])},[_c('span',[_vm._v("Collapse All")])])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }