
<template>
  <div>
    <h3>Special Consideration</h3>
    <p>With highly sensitive species in the area it is suggested that special consideration is to be taken rather than relying on the output of this tool.</p>
  </div>
</template>
<style lang="scss">
</style>
<script>
export default {
  name: 'EfnAnalysisSpeciesSensitivity'
}
</script>
