var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{attrs:{"flat":""}},[_c('v-card-text',{staticClass:"pb-0"},[_c('h3',[_vm._v("Water Rights Licences")])]),(_vm.licencesLoading)?_c('v-card-text',[_c('v-progress-linear',{attrs:{"show":"","indeterminate":""}})],1):_vm._e(),(_vm.licenceData)?_c('v-card-text',{staticClass:"pt-0"},[_c('v-row',[_c('v-col',[_c('v-card',{attrs:{"flat":"","outlined":"","tile":""}},[_c('v-card-title',[_vm._v(" Total annual licenced quantity "),_c('Dialog',_vm._b({attrs:{"smallIcon":""}},'Dialog',_vm.wmd.waterRightsLicenceDemand,false))],1),_c('v-card-text',{staticClass:"info-blue"},[_c('strong',[_vm._v(_vm._s(_vm._f("formatNumber")(_vm.licenceData.total_qty.toFixed(1)))+" m³/year")])])],1)],1)],1),_c('div',{staticClass:"subtitle font-weight-bold"},[_vm._v("Current Licences")]),_c('p',[_vm._v(" Total quantities and individual licence data in this table only reflect current licences. See \"Canceled, Expired and Inactive Licences\" below for inactive licences. ")]),_c('v-data-table',{attrs:{"headers":_vm.licencePurposeHeaders,"items":_vm.licenceData.total_qty_by_purpose.filter(function (x) { return x.licences && x.licences.length; }),"single-expand":_vm.singleExpandLicences,"expanded":_vm.expandedActiveLicences,"item-key":"purpose","show-expand":""},on:{"update:expanded":function($event){_vm.expandedActiveLicences=$event},"click:row":_vm.clearLicenceHighlight},scopedSlots:_vm._u([{key:"item.qty_sec",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatNumber")((item.qty / _vm.secInYear).toFixed(6)))+" ")]}},{key:"item.qty",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatNumber")(item.qty.toFixed(0)))+" ")]}},{key:"item.min",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatNumber")(Math.min.apply(Math, item.licences.map(function (o) { return o.properties.quantityPerYear; } )).toFixed(0)))+" ")]}},{key:"item.max",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatNumber")(Math.max.apply(Math, item.licences.map(function (o) { return o.properties.quantityPerYear; } )).toFixed(0)))+" ")]}},{key:"item.count",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.licences.length)+" ")]}},{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [_c('td',{attrs:{"colspan":headers.length}},[_c('WatershedIndividualLicences',{attrs:{"licences":item.licences}})],1)]}}],null,true)}),_c('div',{staticClass:"subtitle font-weight-bold"},[_vm._v("Canceled, Expired and Inactive Licences")]),_c('v-data-table',{attrs:{"headers":_vm.inactiveLicencePurposeHeaders,"items":_vm.licenceData.total_qty_by_purpose.filter(function (x) { return x.inactive_licences && x.inactive_licences.length; }),"single-expand":_vm.singleExpandInactiveLicences,"expanded":_vm.expandedInactiveLicences,"item-key":"purpose","show-expand":""},on:{"update:expanded":function($event){_vm.expandedInactiveLicences=$event}},scopedSlots:_vm._u([{key:"item.qty_sec",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s((item.qty / _vm.secInYear).toFixed(6))+" ")]}},{key:"item.qty",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatNumber")(item.qty.toFixed(0)))+" ")]}},{key:"item.min",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatNumber")(Math.min.apply(Math, item.inactive_licences.map(function (o) { return o.properties.quantityPerYear; } )).toFixed(0)))+" ")]}},{key:"item.max",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatNumber")(Math.max.apply(Math, item.inactive_licences.map(function (o) { return o.properties.quantityPerYear; } )).toFixed(0)))+" ")]}},{key:"item.count",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.inactive_licences.length)+" ")]}},{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [_c('td',{attrs:{"colspan":headers.length}},[_c('WatershedIndividualLicences',{attrs:{"licences":item.inactive_licences}})],1)]}}],null,true)}),_c('v-dialog',{attrs:{"persistent":""},model:{value:(_vm.show.editingAllocationValues),callback:function ($$v) {_vm.$set(_vm.show, "editingAllocationValues", $$v)},expression:"show.editingAllocationValues"}},[_c('MonthlyAllocationTable',{attrs:{"allocation-items":_vm.licenceData.total_qty_by_purpose,"key-field":"purpose"},on:{"close":_vm.closeEditAllocationTableDialog}})],1),_c('v-card-actions',[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"small":"","depressed":"","light":""},on:{"click":_vm.openEditAllocationTableDialog}},on),[_c('v-icon',{attrs:{"small":"","color":"primary"}},[_vm._v(" mdi-tune ")]),_vm._v(" Monthly allocation coefficients ")],1)]}}],null,false,2068822280)},[_c('span',[_vm._v("Configure monthly allocation coefficients")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"ml-2",attrs:{"small":"","depressed":"","light":""},on:{"click":_vm.toggleWaterLicenceLayerVisibility}},on),[_c('v-icon',{attrs:{"small":""}},[_vm._v(" layers ")]),_vm._v(" "+_vm._s(_vm.isLicencesLayerVisible ? 'Hide' : 'Show')+" points on map ")],1)]}}],null,false,1022281684)},[_c('span',[_vm._v(_vm._s(_vm.isLicencesLayerVisible ? 'Hide' : 'Show')+" Water Rights Licences Layer")])])],1)],1):(!_vm.licencesLoading)?_c('v-card-text',[_vm._v(" No licences found ")]):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }